@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  background: var(--color-neutral-900);
  box-sizing: border-box;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

:root {
  --color-primary: #244077;
  --color-secondary: #77abd6;
  --color-neutral: #969696;
  --color-neutral-100: #000;
  --color-neutral-200: #4b4b4b;
  --color-neutral-300: #646464;
  --color-neutral-400: #7c7c7c;
  --color-neutral-500: #969696;
  --color-neutral-600: #afafaf;
  --color-neutral-700: #c8c8c8;
  --color-neutral-800: #e1e1e1;
  --color-neutral-900: #f5f5f5;
  --color-neutral-1000: #fff;
  --color-black: #000;
  --color-pink: #e79eab;
  --color-tan: #ecc87b;
  --color-tan-light: #f4e0be;
}

*::selection {
  background: var(--color-secondary);
}

/* Hides tinyMCE warning prompting to register with tiny.cloud.
 * This product does not need this as we are only using the editor for formatting the HTML. */
.tox-notifications-container {
  display: none !important;
}
